@tailwind base;

/* @layer base {
    html {
        @apply text-charcoal;
    }
} */

@tailwind components;
@tailwind utilities;

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}
