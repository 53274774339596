/* Firefox */
.custom-scroller {
    scrollbar-width: thin;
    scrollbar-color: #c2c2c2;
}

/* Chrome, Edge, and Safari */
.custom-scroller::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}

.custom-scroller::-webkit-scrollbar-track {
    background: #efefef;
    border-radius: 3px;
}

.custom-scroller::-webkit-scrollbar-thumb {
    background-color: #c2c2c2;
    border-radius: 8px;
    border: 1px solid #e3f2fd;
}
