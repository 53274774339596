/* Hide weekend days */
.performance-date-picker thead tr th:nth-child(6) {
    display: none;
}

.performance-date-picker thead tr th:nth-child(7) {
    display: none;
}

.performance-date-picker tbody tr td:nth-child(6) {
    display: none;
}

.performance-date-picker tbody tr td:nth-child(7) {
    display: none;
}

/* adjust for hidden days */
.performance-date-picker table {
    width: 100%;
    max-width: 100%;
}

.highcharts-scrollbar {
    display: none;
}

.highcharts-credits {
    display: none;
}
