.slide-animation {
    animation: slide 200s linear infinite;
}

@keyframes slide {
    0% {
        left: 0;
    }

    100% {
        left: -1000%;
    }
}
